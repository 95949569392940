import styled from "styled-components/macro"

export const ContainerElement = styled.div`
  display: flex;
`

export const ArrowElement = styled.img`
  position: absolute;
  right: -50px;
  margin-top: 15px;
`

export const WorkflowItemContainer = styled.div`
  margin-bottom: 125px;
`
export const WorkflowIndexContainer = styled.div`
  padding: 15px 0;
`

type WorkflowItemImageElementProps = {
  src: string
}

export const WorkflowItemImageElement = styled.div<
  WorkflowItemImageElementProps
>`
  background: url(${props => props.src}) no-repeat center center/cover;
  width: calc(100% - 75px);
  height: 500px;
  margin: 75px auto;
`

export const WorkflowProjectContainerElement = styled.div`
  padding: 15px 0;
`

export const ArrowContainerElement = styled.div`
  display: flex;
  justify-content: center;
`

export const TrianglesElement = styled.img`
  margin-top: 105px;
  z-index: 1;
  max-height: 600px;
  @media (max-width: 768px) {
    display: none;
  }
  position: absolute;
  z-index: 9999;
  top: -256px;
  width: 100%;
  left: 0;
`
