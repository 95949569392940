import React from "react"
import "typeface-roboto-mono"
import { Service } from "../Service/Service"
import { About } from "./sections/About/About"

export const TechnicalSoftware: React.FC = () => {
  return (
    <Service
      heroProps={{
        title: "Technische software",
        technicalDetails: "Online - Offline - On premise",
        subTitle:
          "We leggen de brug tussen uw hardware en software.",
        infoTexts: ["Verhoogde productiviteit", "Persoonlijke support", "Op maat en als service"],
      }}
    >
      <About />
    </Service>
  )
}
