import React from "react"
import Layout from "../components/layout"
import { TechnicalSoftware as TechnicalSoftwareComponent } from "../components/pages/services/TechnicalSoftware/TechnicalSoftware"
import SEO from "../components/seo"

const TechnicalSoftware = () => (
  <Layout>
    <SEO
      description="Techniek vloeit door de aderen van Disc, het beweegt ons en uw bedrijf."
      title="Technische software"
      lang="nl"
    />
    <TechnicalSoftwareComponent />
  </Layout>
)

export default TechnicalSoftware
