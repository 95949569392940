import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Grid } from "../../../../../../components/general/Grid/Grid"
import { Section } from "../../../../../../components/general/Section/Section"
import {
  BlackGridTheme,
  Color,
  FontWeight,
  GrayGridTheme,
  YellowGridTheme,
} from "../../../../../../data/style/variables"
import trianglesSvg from "../../../../../../images/SVG/Frame 59.svg"
import { Bold } from "../../../../../general/Bold/Bold"
import {
  Paragraph,
  TitleH2,
  TitleH2Article,
  TitleH3,
} from "../../../../../general/Typography/Typography"
import { TypographyAnimation } from "../../../../../general/TypographyAnimation/TypographyAnimation"
import {
  ContainerElement,
  TrianglesElement
} from "./styled"

export const About: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      factory: file(relativePath: { eq: "shutterstock_732811756.jpg" }) {
        childImageSharp {
          sizes(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpSizes
          }
        }
      }
    }
  `)

  return (
    <>
      <ContainerElement>
        <Grid
          gridElementProps={{
            baseHeight: "auto",
            ...BlackGridTheme,
            detailTextColor: Color.DarkGray,
            spaced: true,
            noOverFlowHidden: true,
          }}
          title={
            <TitleH2 fontWeight={FontWeight.Light} color={Color.White}>
              Techniek vloeit door de aderen van Disc, het beweegt{" "}
              <Bold background={Color.White}>ons</Bold> en{" "}
              <Bold background={Color.White}>uw bedrijf</Bold>.
            </TitleH2>
          }
        >
          <Section></Section>
          <Section></Section>
          <Section>
            <TrianglesElement src={trianglesSvg} />
          </Section>
        </Grid>
      </ContainerElement>

      <ContainerElement>
        <Grid
          gridElementProps={{
            baseHeight: "auto",
            ...GrayGridTheme,
            color: Color.Black,
            detailTextColor: Color.DarkGray,
            noOverFlowHidden: true,
          }}
          title={
            <TitleH2Article color={Color.Black}>
              We helpen u met <Bold>innoveren</Bold> op technisch gebied.
            </TitleH2Article>
          }
        >
          <Section>
            <TitleH3 color={Color.Black}>Right time right tools</TitleH3>
            <Paragraph color={Color.Black}>
              Met <Bold>25 jaar ervaring</Bold> in automatisatie, kunnen we uw
              bedrijf naar de next level krijgen. We doen dit door de juiste
              tools en services in te zetten bij uw problemen. We leveren tools
              voor technische doeleinden zoals 3D CAD software, maar ook voor
              doeleinden zoals eindinspectie of tijdregistratie.
            </Paragraph>
          </Section>
          <Section>
            <TitleH3 color={Color.Black}>
              Robuust en betrouwbaar maatwerk
            </TitleH3>
            <Paragraph color={Color.Black}>
              We leveren vakwerk waar u altijd op kunt bouwen. Ook leveren we
              volledige support en staan we altijd voor u klaar. Met onze 25
              jaar aan ervaring in de industrie is geen enkel probleem
              onmogelijk om op te lossen.
            </Paragraph>
          </Section>
          <Section></Section>
        </Grid>
      </ContainerElement>
      <ContainerElement>
        <Grid
          gridElementProps={{
            baseHeight: "750px",
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${data.factory.childImageSharp.sizes.src}) no-repeat center center/cover`,
            ...BlackGridTheme,
            detailTextColor: Color.DarkGray,
            spaced: true,
          }}
        >
          <Section></Section>
        </Grid>
      </ContainerElement>
      <ContainerElement>
        <Grid
          gridElementProps={{
            baseHeight: "auto",
            ...GrayGridTheme,
            color: Color.Black,
            detailTextColor: Color.DarkGray,
            noOverFlowHidden: true,
          }}
          title={
            <TitleH2Article color={Color.Black}>
              klanten en techniek <Bold>samenbrengen</Bold>.
            </TitleH2Article>
          }
        >
        </Grid>
      </ContainerElement>
      <ContainerElement>
        <Grid
          gridElementProps={{
            baseHeight: "auto",
            ...YellowGridTheme,
            color: Color.Black,
            detailTextColor: Color.DarkGray,
            noOverFlowHidden: true,
            spaced: true,
          }}
        >
          <Section>
            <TypographyAnimation>
              {/* omzet en productiviteit*/}
              <TitleH2 color={Color.Black}>
                Techniek in uw bedrijf is belangrijk. We maken de{" "}
                <Bold>software</Bold> voor uw <Bold>klanten en personeel</Bold>{" "}
                om efficiënt met de technieken om te gaan. Hiermee wordt de{" "}
                <Bold>omzet</Bold> verhoogt en de <Bold>bedrijfskosten</Bold>{" "}
                verlaagt.
              </TitleH2>
            </TypographyAnimation>
          </Section>
        </Grid>
      </ContainerElement>
    </>
  )
}
